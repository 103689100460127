<template>
    <div class="landing-bg full-page">
        <navbar/>
        <div class="landing-hero">
            <h1 class="login-small-title">LA PLATEFORME DE CANDIDATURE ET D’INSCRIPTION AU CYCLE DOCTORAL</h1>
            <div class="ui grid">
                <div class="ui six wide column form mt-4 ml-1">
                    <h2 class="title"> Réinitialiser votre mot de passe </h2>
                    <div class="ui form" id="form" style="margin: 10px 20px;">
                       <!-- <div class="required field">
                            <label class="label"> Code reçu par email </label>
                            <input type="password" v-model.trim="$v.user_info.token.$model"/>

                            &lt;!&ndash; error message &ndash;&gt;
                            <div class="ui negative message" v-if="$v.user_info.token.$error">
                                <p><i class="info circle icon"></i> ancien mot de passe est obligatoire </p>
                            </div>
                        </div>-->

                        <div class="required field">
                            <label class="label"> Entrer votre nouveau mot de passe </label>
                            <input type="password" v-model.trim="$v.user_info.new_pass.$model"/>

                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.user_info.new_pass.$error">
                                <p v-if="!$v.user_info.new_pass.required">
                                    <i class="info circle icon"></i>nouveau mot de passe est obligatoire
                                </p>
                                <p v-else-if="!$v.user_info.new_pass.strongPassword">
                                    <i class="info circle icon"></i>
                                    Le nouveau mot de passe doit comporter au moins 8 caractères, un chiffre et une
                                    lettre
                                    majuscule.
                                </p>
                            </div>
                        </div>

                        <div class="required field">
                            <label class="label"> Confirmer votre nouveau mot de passe </label>
                            <input type="password" v-model="$v.confirm_pass.$model"/>

                            <!-- error message -->
                            <div class="ui negative message" v-if="$v.confirm_pass.$error">
                                <p><i class="info circle icon"></i> Les mots de passe ne correspondent pas. </p>
                            </div>
                        </div>

                    </div>
                    <div style="text-align: center; margin: 10px">
                        <button class="mx-auto login-button" :class="{loading: isBusy}" :disabled="isBusy" @click="save">
                            Enregistrer
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import {required, sameAs} from 'vuelidate/lib/validators'
    import navbar from "@/components/landingNav.vue"

    export default {
        name: "pwdInitialisation",
        components: {
            navbar
        },
        data() {
            return {
                user_info: {
                    new_pass: null
                },
                confirm_pass: null,
                isBusy: false
            }
        },
        validations() {
            return {
                user_info: {
                    new_pass: {
                        required,
                        strongPassword(new_pass) {
                            return (
                                /[a-z]/.test(new_pass) &&
                                /[A-Z]/.test(new_pass) &&
                                /[0-9]/.test(new_pass) &&
                                new_pass.length >= 8
                            );
                        }
                    }
                },
                confirm_pass: {
                    sameAsPassword: sameAs(() => {
                        return this.user_info.new_pass
                    })
                }
            }
        },
        computed:{
            user_id(){
                return this.$route.query.id
            },
            user_token(){
                return this.$route.query.token
            }
        },
        methods: {
            save() {
                log('change pass:');

                if(this.isBusy)
                    return;

                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                this.isBusy = true

                this.$store.dispatchAsync(this.$SHARED.services.etudiant.resetPassword, {
                    ...this.user_info,
                    token : this.user_token,
                    user_id: this.user_id
                }).then(data => {
                    this.clearFields()
                    this.$notify({
                        group: 'user-message',
                        type: 'success', // warn , error, success, info
                        text: this.$SHARED.messages.user.update_succeeded
                    });
                    this.$router.push({name: "login"});

                }).finally(() => this.isBusy = false )
            },
            clearFields() {
                this.user_info = {
                    old_pass: null,
                    new_pass: null
                }
                this.confirm_pass = null
            }
        },

        mounted() {
            console.log("@@@@@@@@@@@@@@@ ", this.$route.query.id)
        }
    }
</script>

<style scoped>
    .landing-bg {
        background-image: url("../assets/images/bg_landing.jpg") !important;
        background-size: cover;
        /* height: 100%; */
        /* background-color: var(--main-color-red); */
    }

    .full-page {
        /* display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center; */
        height: 100vh;
        width: 100vw;
    }

    .landing-hero {
        margin-top: 6em;
        padding: 0em 2em;
    }

    .login-small-title {
        font-size: 1.5em;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        color: var(--main-color-blue);
    }

    .login-button {
        padding: 0.5em 0.5em;
        background-color: var(--main-color-blue);
        color: #FFFFFF;
        font-weight: 700;
        font-size: 1.5em;
        /* text-transform: uppercase; */
        cursor: pointer;
        line-height: 1;
        border: none;

    }

    .title {
        text-align: center;
        font-size: 1.8em;
        line-height: 68px;
        text-transform: uppercase;
        border-radius: 10px;
        padding: 0.1em 0.4em;
        color: var(--main-color-blue);
        margin: 0;
        font-weight: 700;
    }

    .label {
        font-size: 14px !important;
    }

    .action-buttons {
        margin-top: 30px !important;
    }
</style>
